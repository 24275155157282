export const jobs = [
  {
    title: "Software Developer",
    companyTitle: "Paycom Payroll, LLC.",
    location: "Dallas, Texas",
    startDate: "May 2023",
    endDate: "October 2024",
    bulletPoints: [
      "Engineered high-quality, reusable code to build and optimize dynamic user interfaces for various platforms, supporting Paycom's Human Capital Management (HCM) solutions.",
      "Delivered and enhanced core modules in Position Management, Documents, Checklists, I-9, and E-Verify to drive productivity and adaptability across industries.",
      "Customized development to meet client-specific requirements, including Electronic Data Interchange (EDI) integrations, enabling product scalability for diverse applications.",
      "Collaborated closely with multi-disciplinary teams, including quality assurance and application support, to ensure robust and effective deployments, often utilizing Pair and Mob Programming practices.",
      "Leveraged project management, issue tracking, and version control tools to enhance team productivity and maintain clear communication across project stages.",
      "Actively participated in Agile-driven development cycles with daily stand-ups, achieving rapid iteration and continuous delivery in a fast-paced environment.",
    ],
  },
  {
    title: "Applications Engineering Intern",
    companyTitle: "Texas Instruments, Inc.",
    location: "Richardson, Texas",
    startDate: "May 2022",
    endDate: "August 2022",
    bulletPoints: [
      "Partnered with cross-functional teams (design, marketing, product definition) to contribute to successful product launches, aligning technical development with user needs.",
      "Provided critical technical support by reviewing customer designs, debugging, and implementing solutions to enhance customer satisfaction and product reliability.",
      "Conducted customer seminars and distributor training, deepening product knowledge and improving troubleshooting techniques.",
      "Developed hands-on proficiency with digital and analog components, sharpening technical skills in schematic layouts, circuit analysis, and diagnostic tools.",
      "Applied programming expertise in C/C++ and LabView to support applications development, demonstrating flexibility and problem-solving in dynamic engineering settings.",
    ],
  },
  {
    title: "Farm Hand",
    companyTitle: "B&D McWhirter Farms",
    location: "Plains, TX",
    startDate: "October 2012",
    endDate: "May 2022",
    bulletPoints: [
      "Image crops circles via drone.",
      "Utilized Climate FieldView and Drone Deploy apps to map fields to experiment with and predict product yield.",
      "Operate all farm machinery (ATVs, forklifts, tractors, spray rigs and pivots).",
      "Manage water pressure.",
      "Troubleshoot mechanical failure in equipment or processes.",
      "Work with chemical application imperative to crop production.",
      "Haul materials and equipment to various locations efficiently.",
    ],
  },
  {
    title: "Ropes Counselor",
    companyTitle: "Camp Timberline",
    location: "Estes Park, CO",
    startDate: "May 2020",
    endDate: "July 2020",
    bulletPoints: [
      "Fostered a healthy Christian environment for children from ages 6-17",
      "Obtained certification in Wilderness First Aid (WFA) and Cariopulmonary Resuscitation (CPR)",
      "Obtained certification for on-site harnessed activities that include a Zip Line, High Ropes Course, Rock Wall, etc.",
    ],
  },
  {
    title: "SPC Campus Ambassador",
    companyTitle: "South Plains College",
    location: "Levelland, Texas",
    startDate: "August 2019",
    endDate: "December 2019",
    bulletPoints: [
      "Provided campus tours to guests interested in South Plains College (SPC).",
      "Served as usher at all SPC graduation ceremonies.",
      "Represented SPC in interviews, publications and commercials",
      "Provided service to all scholarship events hosted at SPC.",
      "Utilized drone to provide video footage of campus for television commercials",
    ],
  },
  {
    title: "Math Tutor",
    companyTitle: "South Plains College",
    location: "Levelland, Texas",
    startDate: "August 2018",
    endDate: "December 2019",
    bulletPoints: [
      "Utilized interpersonal skills to successfully tutor students from a variety of cultural backgrounds in mathematical courses as advanced as Calculus II.",
      "Programmed in-class assignments with C++ language and currently learning Object-Oriented Programming.",
    ],
  },
  {
    title: "UIL Invitational Speech Competition Judge",
    companyTitle: "South Plains College",
    location: "Levelland, Texas",
    startDate: "March 2019",
    endDate: undefined,
    bulletPoints: [
      "Judged and ranked student competitors of informative speaking, poetry and prose",
      "Evaluated eye contact, clarity, emotion, relevance, relatability and originality",
    ],
  },
];
